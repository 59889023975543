import React from "react"
import "../InfoPages.css"
import "./Screenshots.css"

import { faWindowClose } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

// Comments
// Maximum of 8 screenshots, otherwise change the forloop below

function Screenshots({ id }) {
  const showPicture = (e) => {
    let fullPicture = document.getElementById(`${e.target.id}-full`)
    fullPicture.style.display = "block"
  }

  const hidePicture = (e) => {
    const allHide = document.getElementsByClassName(`full`)
    for (let i = 0; i < allHide.length; i++) {
      allHide[i].style.display = "none"
    }
  }

  const fullElements = []
  const thumbnailElements = []

  for (var i = 0; i < 8; i++) {
    try {
      fullElements.push(
        <div
          className="full"
          id={`screenshot${i + 1}-full`}
          onClick={hidePicture}>
          <div className="closeX">
            <FontAwesomeIcon icon={faWindowClose} />
          </div>
          <img
            src={require(`./screenshots/${id}/full${i + 1}.jpg`).default}
            className="full-image"
            alt="In deze escaperoom kan je in 360 graden om je heen kijken"
          />
        </div>
      )

      thumbnailElements.push(
        <img
          id={`screenshot${i + 1}`}
          className="thumbnail"
          src={require(`./screenshots/${id}/thumbnail${i + 1}.jpg`).default}
          onClick={showPicture}
          alt="In deze escaperoom kan je in 360 graden om je heen kijken"
        />
      )
    } catch {}
  }

  return (
    <div className="info-screenshots">
      {fullElements}
      {thumbnailElements}
    </div>
  )
}

export default Screenshots
