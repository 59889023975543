import React, { useContext } from "react"
import "./EscapeRoomTile2.css"
import DiffIcon from "./DiffIcon"
import {
  faEuroSign,
  faClock,
  faShoppingCart,
  faInfoCircle,
  faUsers,
} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Link, useHistory } from "react-router-dom"

import { AppContext } from "../../shared/context/AppContext"

function EscapeRoomTile({
  title,
  titleEN,
  difficulty,
  estTime,
  price,
  text,
  textEN,
  id,
  linkto,
  players,
  live,
  themeColor,
  gifName,
  picName,
}) {
  const history = useHistory()
  const { language } = useContext(AppContext)

  const redirectToInfo = () => {
    if (live) {
      history.push(`/escaperooms/info/${linkto}`)
    }
  }

  return (
    <div className="EscapeRoomTile">
      <style>
        {`.${id}-background-color {background-color: ${themeColor};} 
        .${id}-color {color: ${themeColor};}`}
      </style>
      <div className="innerERtile">
        <div className="ERpicture" onClick={redirectToInfo}>
          <img
            alt={title}
            className="gif-tile-img"
            src={require(`../img/${gifName}`).default}
          />
          <img
            alt={title}
            className="static-tile-img"
            src={require(`../img/${picName}`).default}
          />
        </div>
        <div className="ERinfo">
          <div className="infoItem">
            {language === "NL" ? "Moeilijkheid:" : "Difficulty"}
            <DiffIcon diff={difficulty} className={`infoItem ${id}-color`} />
          </div>
          <div className="infoItem">
            <span className={`infoItem ${id}-color`}>
              <FontAwesomeIcon icon={faClock} />{" "}
            </span>{" "}
            {estTime} {language === "NL" ? "minuten" : "minutes"}
          </div>
          <div className="infoItem">
            <div>
              <span className={`infoItem ${id}-color`}>
                <FontAwesomeIcon icon={faUsers}></FontAwesomeIcon>
              </span>{" "}
              {players} {language === "NL" ? "spelers" : "players"}
            </div>
          </div>
          <div className="infoItem">
            <div>
              <span className={`infoItem ${id}-color`}>
                <FontAwesomeIcon icon={faEuroSign}></FontAwesomeIcon>
              </span>{" "}
              {price > 0
                ? `${price} / team`
                : language === "NL"
                ? "Gratis!"
                : "Free!"}
            </div>
          </div>
        </div>
        <div className="ERdescr" onClick={redirectToInfo}>
          <h2 className="ERtitle">{language === "NL" ? title : titleEN}</h2>
          <p className="ERtext">{language === "NL" ? text : textEN}</p>
        </div>
        {live ? (
          <div className="tileButtons">
            <Link to={`/escaperooms/info/${linkto}`}>
              <div className={`new-info-button ${id}-background-color`}>
                <div className="buttonIconRooms">
                  <FontAwesomeIcon icon={faInfoCircle}> </FontAwesomeIcon>{" "}
                </div>
                Info
              </div>
            </Link>

            {price !== 0 ? (
              <Link to={`/escaperooms/pay/${linkto}`}>
                <div className={`new-info-button ${id}-background-color`}>
                  <div className="buttonIconRooms">
                    <FontAwesomeIcon icon={faShoppingCart}> </FontAwesomeIcon>{" "}
                  </div>
                  {language === "NL" ? "Kopen" : "Buy"}
                </div>
              </Link>
            ) : (
              ""
            )}
          </div>
        ) : (
          <div className="tileButtons">
            <div
              className={`new-info-button ${id}-background-color button-inactive`}>
              {language === "NL" ? "Komt binnenkort!" : "Coming soon!"}
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default EscapeRoomTile
