const DemoKlassementData = [
  { teamname: "Delfterdam", totaltime: 3501833 },
  { teamname: "Niet DeWinnaarsVanDeVorigeKeer", totaltime: 4353976 },
  { teamname: "Keep Escaping", totaltime: 5312198 },
  { teamname: "Team Weltschmerz", totaltime: 5755367 },
  { teamname: "Lizzys Squad", totaltime: 6937203 },
  { teamname: "Croque N Lock", totaltime: 7043104 },
  { teamname: "Muzikaal Chillen Minus Arthur", totaltime: 7424079 },
  { teamname: "De Grote Stappen", totaltime: 8253979 },
  { teamname: "Je moeder heet Henk", totaltime: 8384142 },
  { teamname: "De Rode Panda", totaltime: 8474331 },
  { teamname: "Piet Piraat", totaltime: 8560237 },
  { teamname: "TZN", totaltime: 9555507 },
  { teamname: "Haagse Zeehelden", totaltime: 9707390 },
  { teamname: "GSOV", totaltime: 9783210 },
  { teamname: "En het eten ook", totaltime: 9957301 },
  { teamname: "Suzan en Freak", totaltime: 10506225 },
  { teamname: "Allehage", totaltime: 10784655 },
  { teamname: "Speurzelaars", totaltime: 10829615 },
  { teamname: "Charnine", totaltime: 11110846 },
  { teamname: "Escape4Dummies", totaltime: 11345368 },
  { teamname: "De brommende walvissen", totaltime: 11933346 },
  { teamname: "Rima", totaltime: 12331373 },
  { teamname: "Abelqueens", totaltime: 12761402 },
  { teamname: "cavinita", totaltime: 12843119 },
  { teamname: "Op Afstand", totaltime: 12909885 },
  { teamname: "Blekje", totaltime: 12936144 },
  { teamname: "Go4TheWin", totaltime: 13262816 },
  { teamname: "Escapefanaten", totaltime: 13275222 },
  { teamname: "De Gevulde Koek", totaltime: 13298712 },
  { teamname: "Obviously", totaltime: 13328156 },
  { teamname: "De Bozos", totaltime: 13408078 },
  { teamname: "pandas", totaltime: 13755357 },
  { teamname: "Daap", totaltime: 14017213 },
  { teamname: "LonelyKoning", totaltime: 14049784 },
  { teamname: "WeGerakenErNooitUit", totaltime: 14303250 },
  { teamname: "Briljante Bossche Bollebozen", totaltime: 14359700 },
  { teamname: "Gamefriends", totaltime: 14547783 },
  { teamname: "KiWaRePa", totaltime: 14641973 },
  { teamname: "Bananen", totaltime: 14875932 },
  { teamname: "TeM de Escape", totaltime: 15560494 },
  { teamname: "Jut en Jul", totaltime: 16115659 },
  { teamname: "666", totaltime: 16214330 },
  { teamname: "Vuurvreters", totaltime: 16473444 },
  { teamname: "De heusjes", totaltime: 16603499 },
  { teamname: "Bad moms break free", totaltime: 16622934 },
  { teamname: "De Buurtjes", totaltime: 16734406 },
  { teamname: "CrimiCLG", totaltime: 17562276 },
  { teamname: "ReLiJe", totaltime: 18973291 },
  { teamname: "Roosen", totaltime: 20331142 },
  { teamname: "MaMoPi", totaltime: 21932615 },
  { teamname: "Yeljoy", totaltime: 22337107 },
  { teamname: "36bijna37", totaltime: 23911530 },
  { teamname: "Solo Annie", totaltime: 39951565 },
]

export default DemoKlassementData
