import React, { useState, useContext, useEffect } from "react"
import "./Klassement.css"
import { AppContext } from "../../shared/context/AppContext"
import KlassementTable from "./KlassementTable"
import AlgemeenKlassementData from "./AlgemeenKlassementData"

function AlgemeenRanking() {
  const { language } = useContext(AppContext)
  const [isLoading, setIsLoading] = useState(false)
  const [notFound, setNotFound] = useState(false)
  const [klassementData, setKlassementData] = useState()

  useEffect(() => {
    setKlassementData(AlgemeenKlassementData)
  })

  const introtext = (
    <p className="intro-ranking">
      {language === "NL"
        ? "In het Algemeen Klassement worden de totalen tijden voor alle rondes bij elkaar opgeteld. Dit is dus de totale tijd voor elke ronde inclusief straftijd door het kopen van hints of door het invoeren van foute antwoorden. Dit klassement wordt aan het einde van elke ronde geupdated."
        : "In the general standings, the total times for all rounds are added together. So this is the total time for each round including penalty time for buying hints or entering wrong answers. This ranking gets updated at the end of every round."}
    </p>
  )

  if (isLoading) {
    return (
      <div className="Klassement">
        <div className="PageContentWrapper" id="klassement-wrapper">
          {introtext}
          <h2 id="loading">Loading...</h2>
        </div>
      </div>
    )
  } else if (notFound) {
    return <div></div>
  } else {
    return (
      <div className="Klassement">
        <div className="PageContentWrapper" id="klassement-wrapper">
          {introtext}
          {klassementData ? <KlassementTable data={klassementData} /> : ""}
        </div>
      </div>
    )
  }
}

export default AlgemeenRanking
