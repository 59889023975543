const escapeData = [
  // {
  //   id: "miniER",
  //   order: 5,
  //   linkto: "miniescaperoom",
  //   URL: "https://escapemystery.nl/dewerfkelder",
  //   title: "De Werfkelder",
  //   titleEN: "The Werfkelder",
  //   subTitle: "Hoe lang heb jij nodig?",
  //   subTitleEN: "How quick can you solve it?",
  //   difficulty: 2,
  //   estTime: "10 - 30",
  //   players: "1 - 2",
  //   price: 0,
  //   live: true,
  //   english: true,
  //   numParts: 1,
  //   themeColor: "rgb(218, 0, 29)",
  //   gifName: "Gif_GratisER.gif",
  //   picName: "Titel_image_Werfkelder.jpg",
  //   bannerName: "Banner_image_Werfkelder.jpg",
  //   bannerNameSmall: "Banner_image_Werfkelder_small.jpg",
  //   text: "Je wordt wakker na een avond in de bar en alle deuren zitten op slot. Verspreid door de werfkelder liggen clues die je wel eens verder zouden kunnen helpen. Deze escape room is goed met 1 of 2 mensen te spelen en is niet al te moeilijk, maar zeker niet minder leuk. Deze mini-escape room speelt zich af in de historische werfkelder van studentenvereniging S.S.R.-N.U in Utrecht en is gratis te spelen!",
  //   textEN:
  //     "You wake up after an evening in the bar and all doors are locked. Scattered throughout the Werfkelder are clues that could help you further. This escape room can be played well with 1 or 2 people and is not too difficult, but certainly not less fun. This mini-escape room takes place in the historic Werfkelder of student association S.S.R.-N.U in Utrecht and is free to play!",
  // },
  {
    id: "delftER",
    order: 3,
    linkto: "delftescaperoom",
    URL: "https://escapemystery.nl/ER_het_mysterie_van_delft/",
    title: "Het mysterie van Delft",
    titleEN: "Het mysterie van Delft [Dutch Only]",
    subTitle: "Lossen jullie het op voor het te laat is?",
    subTitleEN: "Can you solve it before it is too late?",
    difficulty: 4,
    estTime: "100 - 150",
    players: "2 - 5",
    price: 25,
    live: true,
    english: false,
    numParts: 3,
    themeColor: "rgb(147 4 3)",
    gifName: "Gif_DelftER.gif",
    picName: "Titel_image_Delft.jpg",
    bannerName: "Banner_image_Delft.jpg",
    bannerNameSmall: "Banner_image_Delft_small.jpg",
    text: "Je wordt gebeld: een spoedklus voor jou als rechercheur! Er wordt iemand verdacht van het maken van explosieven en deze persoon moet onmiddellijk gearresteerd worden! Omdat jij in de buurt woont, is het jouw taak om er meteen op af te gaan. Daar aangekomen is er niemand te bekennen, maar al snel ontdek je een spoor van aanwijzingen. Jullie gaan op zoek naar clues in oude documenten op historische plekken in Delft. Lossen jullie dit mysterie op voordat het te laat is?",
    textEN:
      "Your phone rings: an urgent job for you as a detective! Someone is suspected of making explosives and this person should be arrested immediately! Since you live nearby, it's your job to arrest the suspect. Once you're at the house of the suspect, there's nobody home, but soon you discover a trail of clues. In this Escape Mystery, you will look for clues in old documents at historical places in Delft. Will you solve this mystery before it is too late?",
  },

  {
    id: "kaapseER",
    order: 4,
    linkto: "kaapseescaperoom",
    URL: "https://escapemystery.nl/ER_de_kaapse_kode/",
    title: "De Kaapse Kode",
    titleEN: "The Kaapse Kode",
    subTitle: "Op zoek naar aanwijzingen in een oud café",
    subTitleEN: "Looking for clues in an old café",
    difficulty: 3,
    numParts: 3,
    estTime: "50 - 90",
    players: "2 - 4",
    price: 20,
    live: true,
    english: true,
    themeColor: "rgb(185,114,10)",
    gifName: "Gif_KaapseER.gif",
    picName: "Titel_image_Kaapse.jpg",
    bannerName: "Banner_image_Kaapse.jpg",
    bannerNameSmall: "Banner_image_Kaapse_small.jpg",
    text: "Tijdens het verbouwen van jullie café Kaapse Maria in Rotterdam vinden jullie een fust met tot nu toe nog onbekend bier: de Kaapse Kode. Navraag leert dat het een bier is gebaseerd op een eeuwen oud familierecept. Jullie sluiten het fust aan en het blijkt al snel een hit. Dat smaakt naar meer! Lukt het jullie om het recept te vinden en zo de Kaapse Kode weer te kunnen brouwen?",
    textEN:
      "During the restauration of your café, Kaapse Maria in Rotterdam, you find a cask with unknown beer: ‘de Kaapse Kode’. Some research shows this is a beer based on an ancient family recipe. Together you taste the beer and it’s marvelous! Time to make some more of this wonderful drink. Can you find the recipe so the Kaapse Kode can be brewed again?",
  },
  {
    id: "moordER",
    order: 1,
    linkto: "csi_den_haag",
    URL: "https://escapemystery.nl/ER_csi_den_haag/",
    title: "CSI Den Haag",
    titleEN: "CSI The Hague",
    subTitle: "Op zoek naar clues op een crime scene",
    subTitleEN: "Looking for clues on a crime scene",
    difficulty: 2,
    estTime: "40 - 80",
    players: "2 - 4",
    numParts: 4,
    price: 25,
    live: true,
    english: true,
    themeColor: "rgb(6 183 181)",
    gifName: "Gif_MoordER.gif",
    picName: "Titel_image_CSI_DH.jpg",
    bannerName: "Banner_image_CSI_DH.jpg",
    bannerNameSmall: "Banner_image_CSI_DH_small.jpg",
    text: "Jullie recherche team wordt op een terroristische zaak gezet. Er zijn veel vragen maar weinig aanknopingspunten, totdat er een tip binnen komt van de technische recherche. Jullie gaan als detectives te werk en gaan op zoek naar meer clues. Al snel blijkt er veel meer aan de hand dan jullie op het eerste gezicht dachten...",
    textEN:
      "Your investigative team is being assigned to a terrorist case. There are many questions but few leads, until a tip comes in from the forensic team. You work as detectives and look for more clues. It soon becomes apparent that there is much more going on than you first thought...",
  },
  {
    id: "sterrenER",
    order: 2,
    linkto: "gevaar_uit_de_ruimte",
    URL: "https://escapemystery.nl/ER_gevaar_uit_de_ruimte/",
    title: "Gevaar uit de ruimte",
    titleEN: "Danger from space",
    subTitle: "Kraak alle ruimte puzzels in sterrenwacht Sonnenborgh",
    subTitleEN: "Unravel all space puzzles in observatory Sonnenborgh",
    difficulty: 3,
    estTime: "100 - 150",
    players: "2 - 4",
    price: 25,
    live: true,
    english: false,
    numParts: 3,
    themeColor: "rgb(102 139 168)",
    gifName: "Gif_SterrenER.gif",
    picName: "Titel_image_Ruimte.jpg",
    bannerName: "Banner_image_Ruimte.jpg",
    bannerNameSmall: "Banner_image_Ruimte_small.jpg",
    text: "Een criminele organisatie heeft snode plannen voorbereid vanuit sterrenwacht Sonnenborgh. De politie valt de sterrenwacht binnen en jullie gaan op zoek naar sporen. De criminelen blijken hun plan al in werking gezet te hebben. Zijn jullie al te laat...?",
    textEN:
      "A criminal organization has prepared nefarious plans from the Sonnenborgh observatory. The police invade the observatory and you look for clues. The criminals appear to have already put their plan into action. Are you already too late...?",
  },
];

export default escapeData;
