import React, { useContext } from "react"
import { AppContext } from "../../shared/context/AppContext"

function KlassementTable({ data }) {
  const { language } = useContext(AppContext)

  const KlassementSorted = data.sort((a, b) =>
    parseFloat(b.points - parseFloat(a.points))
  )
  const tablerows = KlassementSorted.map((team, index) => {
    return (
      <tr key={index}>
        <td>{index + 1}</td>
        <td>{team.teamname}</td>
        <td>{team.points}</td>
      </tr>
    )
  })

  return (
    <div className="KlassementTable">
      <table id="klassement-headers" className="klassement-table">
        <tbody>
          <tr>
            <td></td>
            <td>Team:</td>
            <td>{language === "NL" ? "Punten" : "Points"}:</td>
          </tr>
        </tbody>
      </table>
      <div id="klassement-rows-div" className="custom-scrollbar">
        <table className="klassement-table" id="klassement-rows">
          <tbody>{tablerows}</tbody>
        </table>
      </div>
    </div>
  )
}

export default KlassementTable
