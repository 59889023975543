import React, { useState, useContext, useEffect } from "react"
import "./Klassement.css"
import { AppContext } from "../../shared/context/AppContext"
import SprintKlassementTable from "./SprintKlassementTable"
import SprintKlassementData from "./SprintKlassementData"

function AlgemeenRanking() {
  const { language } = useContext(AppContext)
  const [isLoading, setIsLoading] = useState(false)
  const [notFound, setNotFound] = useState(false)
  const [klassementData, setKlassementData] = useState()

  useEffect(() => {
    setKlassementData(SprintKlassementData)
  })

  const introtext = (
    <p className="intro-ranking">
      {language === "NL"
        ? "In elke ronde van de escape competitie zit een sprint puzzel. In het Sprint Klassement krijg je punten afhankelijk van hoe snel je de sprint puzzel hebt voltooid: het snelste team krijgt 24 punten, en vervolgens krijgen de snelste teams 22, 20, 18, 17, 16... enzovoort tot 1. Dit klassement wordt aan het einde van elke ronde geupdated."
        : "Each round of the escape competition has a sprint puzzle. In the Sprint standings you get points depending on how fast you complete the sprint puzzle: the fastest team gets 24 points, the second fastest gets 22, then 20, 18, 17, 16... and so on until 1. This ranking gets updated at the end of every round."}
    </p>
  )

  if (isLoading) {
    return (
      <div className="Klassement">
        <div className="PageContentWrapper" id="klassement-wrapper">
          {introtext}
          <h2 id="loading">Loading...</h2>
        </div>
      </div>
    )
  } else if (notFound) {
    return <div></div>
  } else {
    return (
      <div className="Klassement">
        <div className="PageContentWrapper" id="klassement-wrapper">
          {introtext}
          {klassementData ? (
            <SprintKlassementTable data={klassementData} />
          ) : (
            ""
          )}
        </div>
      </div>
    )
  }
}

export default AlgemeenRanking
