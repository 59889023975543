import React, { useContext, useEffect } from "react"
import "./InfoPages2.css"
import EscapeRoomData from "../EscaperoomsPage/components/EscapeRoomData"
import {
  faPlayCircle,
  faShoppingCart,
  faHourglassHalf,
  faExclamationCircle,
  faLaptop,
  faStreetView,
  faWifi,
  faPencilAlt,
  faGamepad,
} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Link } from "react-router-dom"
import IconBanner from "../shared/elements/IconBanner2"
import { AppContext } from "../shared/context/AppContext"
import { Helmet } from "react-helmet"
import Screenshots from "./components/Screenshots"
import { useParams } from "react-router"

function InfoPage() {
  const { language } = useContext(AppContext)

  const { setActiveHeader } = useContext(AppContext)

  useEffect(() => {
    setActiveHeader("escaperooms")
  }, [])
  const { linktoPar } = useParams()
  const {
    difficulty,
    id,
    estTime,
    price,
    text,
    textEN,
    linkto,
    URL,
    players,
    title,
    titleEN,
    themeColor,
    gifName,
    numParts,
    bannerName,
    bannerNameSmall,
  } = EscapeRoomData.find((x) => x.linkto === linktoPar)
  return (
    <div className="InfoPageWrapper">
      <style>
        {`.${id}-background-color {background-color: ${themeColor};} 
        .${id}-color {color: ${themeColor};}`}
      </style>
      <Helmet>
        <title>Escape room {title} | Escape Mystery</title>
      </Helmet>
      <div className="PageContentWrapper">
        <div className="pictureBanner2">
          <img
            alt={title}
            className="banner-img-big"
            src={require(`./banners/${bannerName}`).default}
          />
          <img
            alt={title}
            className="banner-img-small"
            src={require(`./banners/${bannerNameSmall}`).default}
          />
          <div className="big-screen">
            <IconBanner
              difficulty={difficulty}
              estTime={estTime}
              price={price}
              players={players}
              id={id}
            />
          </div>
        </div>
        <div className="small-screen">
          <IconBanner
            difficulty={difficulty}
            estTime={estTime}
            price={price}
            players={players}
            id={id}
          />
        </div>

        <section className="info-content" id="upper-info-section">
          <div id="buttons-sidebar2">
            <a href={URL} target="_blank" rel="noopener noreferrer">
              <div className={`new-info-button ${id}-background-color`}>
                <div className="buttonIcon">
                  <FontAwesomeIcon icon={faPlayCircle}> </FontAwesomeIcon>{" "}
                </div>
                Start
              </div>
            </a>

            {price !== 0 ? (
              <Link to={`/escaperooms/pay/${linkto}`}>
                <div className={`new-info-button ${id}-background-color`}>
                  <div className="buttonIcon">
                    <FontAwesomeIcon icon={faShoppingCart}></FontAwesomeIcon>
                  </div>
                  {language === "NL" ? "Kopen" : "Buy"}
                </div>
              </Link>
            ) : (
              ""
            )}
          </div>

          <img
            alt=""
            className="info-gif"
            src={require(`../EscaperoomsPage/img/${gifName}`).default}
          />
        </section>

        <section className="info-wrapper">
          <section className="info-content" id="third-info-section">
            <h3 className="info-page-title">
              {language === "NL" ? title : titleEN}
            </h3>
            <div className="PageText" id="EscapeRoomsSubTitle">
              {language === "NL" ? text : textEN}
            </div>{" "}
          </section>
        </section>
        <section className="info-wrapper matrix-bg">
          <section className="info-content" id="fourth-info-section">
            <h3 className="info-page-title">
              {language === "NL" ? "Hoe werkt het?" : "How does it work?"}
            </h3>
            <table id="delftER-hwh" className="info-table">
              <tbody>
                <tr>
                  <td className="hwh-col1">
                    <span className={`fa-table-hwh ${id}-color`}>
                      <FontAwesomeIcon icon={faGamepad} />
                    </span>{" "}
                    <br />
                    <h4>Online game</h4>
                  </td>
                  <td className="hwh-col2">
                    {price > 0
                      ? language === "NL"
                        ? `${title} is een online game die je in je browser kan spelen. Je logt in met jouw toegangscode en je lost puzzels op om de escape room uit te spelen.`
                        : `${titleEN} is an online game that you can play in your browser. Je log in with your acces code and you solve puzzles in order to reach the end.`
                      : language === "NL"
                      ? `${title} is een online game die je in je browser kan spelen. Het is een mini voorbeeld van onze andere escape rooms.`
                      : `${title} is an online game that you can play in your browser. It's a mini example of our other escape rooms.`}
                  </td>
                </tr>
                <tr>
                  <td className="hwh-col1">
                    <span className={`fa-table-hwh ${id}-color`}>
                      <FontAwesomeIcon icon={faStreetView} />
                    </span>{" "}
                    <br />
                    <h4>360&#176;</h4>
                  </td>
                  <td className="hwh-col2">
                    {language === "NL"
                      ? "Je kunt in de escape room 360° om je heen kijken en rondlopen om zo aanwijzingen te vinden en de puzzels op te lossen."
                      : "You can look around in 360° and walk around at any location to find clues and solve the puzzles."}
                  </td>
                </tr>
                {price > 0 ? (
                  <tr>
                    <td className="hwh-col1">
                      <span className={`fa-table-hwh ${id}-color`}>
                        <FontAwesomeIcon icon={faHourglassHalf} />
                      </span>{" "}
                      <br />
                      <h4>{language === "NL" ? "Eigen tempo" : "Own pace"}</h4>
                    </td>
                    <td className="hwh-col2">
                      {language === "NL"
                        ? `${title} bestaat uit ${numParts} delen. Tussen de delen door kan je pauzeren en een andere keer doorgaan. De ${numParts} delen duren in totaal ongeveer ${estTime} minuten.`
                        : `${title} consists if ${numParts} parts. Between these parts you can take a break. The ${numParts} parts will take approximatley ${estTime} minuten.`}
                    </td>
                  </tr>
                ) : (
                  ""
                )}
                <tr>
                  <td className="hwh-col1">
                    <span className={`fa-table-hwh ${id}-color`}>
                      <FontAwesomeIcon icon={faExclamationCircle} />
                    </span>{" "}
                    <br />
                    <h4>Hints</h4>
                  </td>
                  <td className="hwh-col2">
                    {language === "NL"
                      ? "Als je ergens echt niet uitkomt kun je hints krijgen zodat je nooit vast komt te zitten."
                      : "If you're stuck on a puzzle, you can 'buy' the correct answer as a hint. Extra time will then be added to your end time."}
                  </td>
                </tr>
                {price > 0 ? (
                  <tr>
                    <td className="hwh-col1">
                      <span className={`fa-table-hwh ${id}-color`}>
                        <FontAwesomeIcon icon={faLaptop} />
                      </span>{" "}
                      <br />
                      <h4>Devices</h4>
                    </td>
                    <td className="hwh-col2">
                      {language === "NL"
                        ? "Je kunt op meerdere devices (laptops / tablets / mobieltjes) met meerdere mensen tegelijk inloggen met dezelfde code. De tijden, hints en voortgang worden automatisch gesynchroniseerd."
                        : "You can log in on multiple devices (laptops / tablets / mobile phones) with multiple people at the same time with your access code. The times, hints and progress are automatically synchronized."}
                    </td>
                  </tr>
                ) : (
                  ""
                )}
              </tbody>
            </table>
          </section>

          <section className="info-content" id="fifth-info-section">
            <h3 className="info-page-title">
              {language === "NL" ? "Wat heb je nodig?" : "What do you need?"}
            </h3>
            <table id="delftER-hwh" className="info-table">
              <tbody>
                <tr>
                  <td className="hwh-col1">
                    <span className={`fa-table-hwh ${id}-color`}>
                      <FontAwesomeIcon icon={faWifi} />
                    </span>{" "}
                    <br />
                    <h4>Internet</h4>
                  </td>
                  <td className="hwh-col2">
                    {language === "NL"
                      ? "Een laptop of tablet met internetverbinding. Je kunt ook op je mobiel spelen, maar het kleine scherm van een mobiel is niet ideaal om alle hints goed te kunnen lezen en het zou kunnen dat sommige dingen niet goed werken op je mobiel."
                      : "A laptop or tablet with internet connection. You can also play on your mobile phone, but the small screen of a mobile is not ideal for reading all the hints and some things may not work properly on your mobile phone."}
                  </td>
                </tr>
                <tr>
                  <td className="hwh-col1">
                    <span className={`fa-table-hwh ${id}-color`}>
                      <FontAwesomeIcon icon={faPencilAlt} />
                    </span>{" "}
                    <br />
                    <h4>{language === "NL" ? "Notities" : "Notes"}</h4>
                  </td>
                  <td className="hwh-col2">
                    {language === "NL"
                      ? "Het kan handig zijn om pen en papier of een digitaal kladblok bij de hand te houden om de puzzels op te lossen."
                      : "You may find it helpful to use pen and paper or a digital notepad to solve the puzzles."}
                  </td>
                </tr>
              </tbody>
            </table>
          </section>
        </section>
        <section className="info-content" id="second-info-section">
          <h3 className="info-page-title">
            {language === "NL" ? "Impressie" : "Impression"}
          </h3>
          <Screenshots id={id} />
        </section>
      </div>
    </div>
  )
}

export default InfoPage
