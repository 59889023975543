const DemoKlassementData = [
  { teamname: "Delfterdam", points: 46 },
  { teamname: "Team Weltschmerz", points: 41 },
  { teamname: "Niet DeWinnaarsVanDeVorigeKeer", points: 40 },
  { teamname: "TZN", points: 26 },
  { teamname: "Keep Escaping", points: 25 },
  { teamname: "Speurzelaars", points: 24 },
  { teamname: "Croque N Lock", points: 23 },
  { teamname: "Suzan en Freak", points: 20 },
  { teamname: "De Rode Panda", points: 20 },
  { teamname: "cavinita", points: 19 },
  { teamname: "Go4TheWin", points: 16 },
  { teamname: "Je moeder heet Henk", points: 15 },
  { teamname: "Muzikaal Chillen Minus Arthur", points: 15 },
  { teamname: "Piet Piraat", points: 15 },
  { teamname: "WeGerakenErNooitUit", points: 14 },
  { teamname: "Lizzys Squad", points: 14 },
  { teamname: "Briljante Bossche Bollebozen", points: 14 },
  { teamname: "GSOV", points: 13 },
  { teamname: "De heusjes", points: 12 },
  { teamname: "De Grote Stappen", points: 12 },
  { teamname: "Haagse Zeehelden", points: 11 },
  { teamname: "En het eten ook", points: 10 },
  { teamname: "De Bozos", points: 8 },
  { teamname: "Op Afstand", points: 7 },
  { teamname: "Obviously", points: 5 },
  { teamname: "De brommende walvissen", points: 5 },
  { teamname: "Bananen", points: 3 },
  { teamname: "Escape4Dummies", points: 1 },
]

export default DemoKlassementData
