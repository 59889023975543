import React, { useContext } from "react"
import "./PaymentComplete.css"
import PageTitle from "../shared/elements/PageTitle"
import { Helmet } from "react-helmet"

import { useParams } from "react-router"

import { AppContext } from "../shared/context/AppContext"

function PaymentComplete() {
  const { language } = useContext(AppContext)
  const { orderId } = useParams()

  return (
    <div className="PaymentComplete">
      <Helmet>
        <title>Betaal bevestiging | Escape Mystery</title>
      </Helmet>
      <div className="PageContentWrapper">
        <br /> <br />
        <h2 className="zakelijk-h2">
          {language === "NL" ? "Betaal bevestiging" : "Payment Confirmation"}
        </h2>
        <div className="PageSubTitleText" id="EscapeRoomsSubTitle">
          {language === "NL"
            ? "Bedank voor jouw bestelling met ordernummer "
            : "Thank you for your order with reference "}
          {orderId}
        </div>
        <div id="completed" className="PayPageBlock">
          <h3 className="InfoPageTitle">
            {" "}
            {language === "NL" ? "Bevestigingsemail" : "Confirmation email"}
          </h3>
          <p className="simple-text">
            {language === "NL"
              ? `Je krijgt binnen 10 minuten een e-mail met een bevestiging van jouw inschrijving.`
              : `You will receive an confirmation email within 10 minutes.`}
          </p>
        </div>
        <div id="completed" className="PayPageBlock">
          <h3 className="InfoPageTitle">
            {language === "NL"
              ? "Geen e-mail ontvangen?"
              : "Didn't receive an email?"}
          </h3>
          <p className="simple-text">
            {language === "NL"
              ? `Heb je binnen 10 minuten geen e-mail ontvangen? Kijk eerst in de
            spam of ongewenste e-mail. Als je ook daar niks kan vinden, stuur
            dan een mailtje naar helpdesk@escapemystery.nl en noem daarin jouw
            ordernummer: ${orderId}`
              : `Didn't receive an email within 10 minutes? Check the spam or junk email first. If you can't find anything there either, send an email to helpdesk@escapemystery.nl and mention your reference: ${orderId}`}
          </p>
        </div>
      </div>
    </div>
  )
}

export default PaymentComplete
