import React, { useContext } from "react"
import { AppContext } from "../shared/context/AppContext"

function convertMs(timeInMs) {
  let hours = Math.floor((timeInMs / (1000 * 60 * 60)) % 60)
  let minutes = Math.floor((timeInMs / (1000 * 60)) % 60)
  let seconds = Math.floor((timeInMs / 1000) % 60)
  hours = hours < 10 ? "0" + hours : hours
  minutes = minutes < 10 ? "0" + minutes : minutes
  seconds = seconds < 10 ? "0" + seconds : seconds
  return `${hours}:${minutes}:${seconds}`
}

function KlassementTable({ data }) {
  const { language } = useContext(AppContext)

  const KlassementSorted = data.sort((a, b) =>
    parseFloat(a.totaltime - parseFloat(b.totaltime))
  )
  const tablerows = KlassementSorted.map((team, index) => {
    return (
      <tr key={index}>
        <td>{index + 1}</td>
        <td>{team.teamname}</td>
        <td>{convertMs(team.totaltime)}</td>
      </tr>
    )
  })

  return (
    <div className="KlassementTable">
      <table id="klassement-headers" className="klassement-table">
        <tbody>
          <tr>
            <td></td>
            <td>Team:</td>
            <td>{language === "NL" ? "Tijd" : "Time"}:</td>
          </tr>
        </tbody>
      </table>
      <div id="klassement-rows-div" className="custom-scrollbar">
        <table className="klassement-table" id="klassement-rows">
          <tbody>{tablerows}</tbody>
        </table>
      </div>
    </div>
  )
}

export default KlassementTable
