import React, { useContext, useEffect, useState } from "react";
import "./Zakelijk.css";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import {
  faPuzzlePiece,
  faCamera,
  faPaintBrush,
  faGamepad,
  faChevronRight,
  faChevronLeft,
} from "@fortawesome/free-solid-svg-icons";
import swapLogo from "./img/swapLogo.png";
import arcadisLogo from "./img/Arcadis.png";
import schipholLogo from "./img/Schiphol.png";
import minfinLogo from "./img/Minfinlogo.png";
import kaapseLogo from "./img/kaapse.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { AppContext } from "../shared/context/AppContext";

function Zakelijk() {
  const { language, setActiveHeader } = useContext(AppContext);
  const [name, setName] = useState("");
  const [organization, setOrganization] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState(false);
  const [quoteIndex, setQuoteIndex] = useState(0);

  useEffect(() => {
    setActiveHeader("zakelijk");
  }, []);

  const toEmail = () => {
    document
      .getElementById("scroll-to-here")
      .scrollIntoView({ behavior: "smooth" });
  };

  const mouseEnter3 = () => {
    const el = document.getElementById("process-step-3-cover");
    const el_2 = document.getElementById("process-step-name-3");
    el.style.zIndex = "-1";
    el_2.style.zIndex = "-1";
  };

  const mouseLeave3 = () => {
    const el = document.getElementById("process-step-3-cover");
    const el_2 = document.getElementById("process-step-name-3");
    el.style.zIndex = "2";
    el_2.style.zIndex = "3";
  };

  const mouseEnter4 = () => {
    const el = document.getElementById("process-step-4-cover");
    const el_2 = document.getElementById("process-step-name-4");
    el.style.zIndex = "-1";
    el_2.style.zIndex = "-1";
  };

  const mouseLeave4 = () => {
    const el = document.getElementById("process-step-4-cover");
    const el_2 = document.getElementById("process-step-name-4");
    el.style.zIndex = "2";
    el_2.style.zIndex = "3";
  };

  const nextQuote = () => {
    const els = document.querySelectorAll(".quote-div");
    els.forEach((el) => {
      el.style.display = "none";
    });
    let newIndex = (quoteIndex + 1) % els.length;
    els[newIndex].style.display = "block";
    setQuoteIndex(newIndex);
  };

  const previousQuote = () => {
    const els = document.querySelectorAll(".quote-div");
    els.forEach((el) => {
      el.style.display = "none";
    });
    let newIndex = (quoteIndex - 1 + els.length) % els.length;
    els[newIndex].style.display = "block";
    setQuoteIndex(newIndex);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setError(false);
    const submitForm = async () => {
      try {
        const response = await fetch(
          process.env.REACT_APP_BACKENDURL + "/website/form/submit",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              name: name,
              organization: organization,
              email: email,
              message: message,
            }),
          }
        );
        const data = await response.json();

        if (!response.ok) {
          throw new Error(data.errorMsg);
        }
        setSubmitted(true);
      } catch (err) {
        setError(true);
        console.log(err.message);
      }
    };
    submitForm();
  };

  return (
    <div className="Zakelijk">
      <Helmet>
        <title>
          {language === "NL"
            ? "Escape room op maat | Escape Mystery"
            : "Custom escape room | Escape Mystery"}
        </title>
      </Helmet>
      <section className="zakelijk-section lock-bg">
        <section className="zakelijk-content">
          <h2 className="zakelijk-h2">
            {language === "NL"
              ? "Uw locatie als online escape room"
              : "Your location as an online escape room"}
          </h2>
          <h3 className="subtitle-zakelijk">
            {language === "NL"
              ? "Online bedrijfsuitje, teambuilding, educatieve experience, serious game, bekendmaking of onthulling; alles is mogelijk!"
              : "Online company outing, team building, educational experience, serious game or announcement; everything is possible!"}
          </h3>
          <div className="including-div">
            <div className="including-item">
              <div className="including-fa ">
                <FontAwesomeIcon icon={faCamera} />
              </div>
              <p className="including-text ">
                {language === "NL"
                  ? "Wij maken 360° foto's op een door u te kiezen locatie(s), zoals een kantoor, museum of werkplaats."
                  : "We take 360° photos at a location(s) of your choice, such as an office, museum or workshop."}
              </p>
            </div>
            <div className="including-item">
              <div className="including-fa ">
                <FontAwesomeIcon icon={faGamepad} />
              </div>
              <p className="including-text ">
                {language === "NL"
                  ? "Wij creëren een panorama van de foto's en voegen een interactieve laag toe."
                  : "We create a panorama of the photos and add an interactive layer."}
              </p>
            </div>
          </div>
          <div className="including-div">
            <div className="including-item">
              <div className="including-fa ">
                <FontAwesomeIcon icon={faPaintBrush} />
              </div>
              <p className="including-text ">
                {language === "NL"
                  ? "We passen uw eigen (huis)stijl en wensen toe in de escape room en alle puzzels."
                  : "We apply your own corporate identity and wishes in the escape room and all puzzles."}
              </p>
            </div>
            <div className="including-item">
              <div className="including-fa ">
                <FontAwesomeIcon icon={faPuzzlePiece} />
              </div>
              <p className="including-text ">
                {language === "NL"
                  ? "Samen met u bedenken we puzzels. Wij hebben veel puzzels klaar liggen voor inspiratie!"
                  : "Together with you we create the puzzles. We've got many puzzles that can be used as inspiration."}
              </p>
            </div>
          </div>
          <div className="button-wrapper-zakelijk">
            <div onClick={toEmail}>
              <div className="new-info-button auto-size">
                {language === "NL"
                  ? "Neem contact op voor de mogelijkheden"
                  : "Contact us for the possibilities"}
              </div>
            </div>
          </div>
        </section>
      </section>
      <section className="zakelijk-section light-bg">
        <section className="zakelijk-content relative">
          <div
            id="arrow-left-quotes"
            class="arrow-quotes"
            onClick={previousQuote}
          >
            <FontAwesomeIcon icon={faChevronLeft} />
          </div>
          <div id="arrow-right-quotes" class="arrow-quotes" onClick={nextQuote}>
            <FontAwesomeIcon icon={faChevronRight} />
          </div>
          <div className="quote-div" style={{ display: "block" }}>
            <br />
            <h2>Gyazi Johnson</h2>
            <br />
            <br />
            <p>
              <i>
                {language === "NL"
                  ? `"Bijzonder om het jaar samen af te sluiten, virtueel puzzelend in de vertrekhal van ons eigen Schiphol."`
                  : `"It was a special experience to celebrate the holidays together, virtually puzzling on Schiphl Plaza."`}
              </i>
            </p>
            <img src={schipholLogo} alt="Swapfiets" />
          </div>

          <div className="quote-div">
            <br />
            <h2>Bart de Jongh</h2>
            <br />
            <br />
            <p>
              <i>
                {language === "NL"
                  ? `"Digitaal door ons kantoor als escape room, echt helemaal top. Mooi gedaan, we hadden een top teamuitje!"`
                  : `"Digital through our office as an escape room, really top notch. Nicely done, we had a top team outing!"`}
              </i>
            </p>
            <img src={minfinLogo} alt="Swapfiets" />
          </div>

          <div className="quote-div">
            <br />
            <h2>Ella Greer</h2>
            <br />
            <br />
            <p>
              <i>
                {language === "NL"
                  ? `"Dankzij Escape Mystery doen al onze nieuwe collega's door Europa de onboarding virtueel en interactief. Alles ziet er professioneel uit!"`
                  : `"Thanks to Escape Mystery, all our new colleagues across Europe do the onboarding virtually and interactively. Everything looks professional!"`}
              </i>
            </p>
            <img src={swapLogo} alt="Swapfiets" />
          </div>

          <div className="quote-div">
            <br />
            <h2>Lieke Arts</h2>
            <br />
            <br />
            <p>
              <i>
                {language === "NL"
                  ? `"Dankzij Escape Mystery hadden wij een ontzettend leuk
                  teamuitje. Iedereen heeft fanatiek gepuzzeld om bovenin ons
                  klassement te eindigen."`
                  : `"Thanks to Escape Mystery we had a very nice team outing. Everyone has been puzzling fanatically to finish at the top of our standings."`}
              </i>
            </p>
            <img src={arcadisLogo} alt="Swapfiets" />
          </div>
          <div className="quote-div">
            <br />
            <h2>Duider Prins</h2>
            <br />
            <br />
            <p>
              <i>
                {language === "NL"
                  ? `"Gaaf om al puzzelend ook digitaal door onze kroeg heen te
                  kunnen lopen. Foto's maken ging snel en het eindresultaat is
                  super!"`
                  : `"Great to be able to walk through our pub digitally while puzzling. Taking pictures went quickly and the end result is great!"`}
              </i>
              <i></i>
            </p>
            <img src={kaapseLogo} alt="Swapfiets" />
          </div>
        </section>
      </section>
      <section className="zakelijk-section network-bg">
        <section className="zakelijk-content">
          <h2 className="zakelijk-h2">
            {language === "NL"
              ? "Uw escape room in 6 simpele stappen"
              : "Your escape room 6 simple steps"}
          </h2>
          <div className="including-div">
            <div className="including-item">
              <div className="including-fa ">
                <h1 className="themakleur">1</h1>
              </div>
              <p className="including-text ">
                <b>{language === "NL" ? "Intakegesprek" : "Intake"}</b>
                <br />
                {language === "NL"
                  ? "Overleg over de opbouw van de escape room en de puzzels."
                  : "Meeting about the structure and puzzles of the escape room"}
              </p>
            </div>
            <div className="including-item">
              <div className="including-fa ">
                <h1 className="themakleur">2</h1>
              </div>
              <p className="including-text ">
                <b>
                  {" "}
                  {language === "NL"
                    ? "Foto's op locatie"
                    : "Pictures at location"}
                </b>
                <br />
                {language === "NL"
                  ? "Escape Mystery maakt foto's op een door u te kiezen locatie."
                  : "Escape Mystery takes pictures at a location of your choosing."}
              </p>
            </div>
          </div>
          <div className="including-div">
            <div className="including-item">
              <div className="including-fa ">
                <h1 className="themakleur">3</h1>
              </div>
              <p className="including-text ">
                <b>
                  {" "}
                  {language === "NL"
                    ? "Ontwikkelen escape room"
                    : "Developing escape room"}
                </b>
                <br />
                {language === "NL"
                  ? "Escape Mystery bouwt de escape room geheel naar uw wensen."
                  : "Escape Mystery creates the escape room according to your preferences."}
              </p>
            </div>
            <div className="including-item">
              <div className="including-fa ">
                <h1 className="themakleur">4</h1>
              </div>
              <p className="including-text ">
                <b> {language === "NL" ? "Testen" : "Testing"}</b>
                <br />
                {language === "NL"
                  ? "Door ons Escape Mystery test panel en door uzelf."
                  : "By our Escape Mystery test panel and by yourself."}
              </p>
            </div>
          </div>
          <div className="including-div">
            <div className="including-item">
              <div className="including-fa ">
                <h1 className="themakleur">5</h1>
              </div>
              <p className="including-text ">
                <b>
                  {" "}
                  {language === "NL"
                    ? "Feedback verwerken"
                    : "Process feedback"}
                </b>
                <br />
                {language === "NL"
                  ? "Escape mystery verwerkt alle feedback n.a.v. het testen."
                  : "Escape Mystery processes all the feedback after testing."}
              </p>
            </div>
            <div className="including-item">
              <div className="including-fa ">
                <h1 className="themakleur">6</h1>
              </div>
              <p className="including-text ">
                <b> {language === "NL" ? "Spelen!" : "Play!"}</b>
                <br />
                {language === "NL"
                  ? "De escape room is klaar om gespeeld te worden!"
                  : "The escape room is ready to be played!"}
              </p>
            </div>
          </div>
          <br />
          <Link
            to="/demo_virtuele_omgeving/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <div className="new-info-button auto-size">
              {language === "NL"
                ? "Bekijk de demo virtuele omgeving"
                : "Check the demo virtual environmen"}
            </div>
          </Link>
          <div id="scroll-to-here"></div>
        </section>
      </section>
      <section className="zakelijk-section light-bg">
        <section className="zakelijk-content">
          <h2 className="zakelijk-h2">
            {language === "NL" ? "Neem contact met ons op" : "Contact us"}
          </h2>

          <form onSubmit={handleSubmit}>
            <label>
              {language === "NL" ? "Naam" : "Name"}*
              <input
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
              />
            </label>
            <label>
              {language === "NL" ? "Organisatie" : "Organization"}
              <input
                type="text"
                value={organization}
                onChange={(e) => setOrganization(e.target.value)}
              />
            </label>
            <label>
              {language === "NL" ? "E-mailadres" : "E-mail address"}*
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </label>
            <label>
              {language === "NL" ? "Bericht" : "Message"}*
              <textarea
                type="textarea"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                required
              />
            </label>
            {submitted ? (
              <div className="width-100 white-border">
                {language === "NL"
                  ? "Uw bericht is verstuurd. Wij komen spoedig bij u terug."
                  : "Your message is succesfully send"}
              </div>
            ) : (
              <button type="submit" className="width100">
                <div className="new-info-button auto-size">
                  {language === "NL" ? "Versturen" : "Submit"}
                </div>
              </button>
            )}
          </form>

          {error ? (
            <div className="width-60 white-border background-themakleur">
              {language === "NL"
                ? "Er ging iets mis bij het verzenden. Probeer het nog eens."
                : "Something went wrong with submitting. Please try again"}
            </div>
          ) : (
            ""
          )}

          <div className="width-60 white-border">
            {language === "NL"
              ? "Of stuur een e-mail naar "
              : "Or send us an e-mail at "}
            <a
              href="mailto:zakelijk@escapemystery.nl"
              target="_blank"
              rel="noreffere"
              className="themakleur"
            >
              zakelijk@escapemystery.nl
            </a>
          </div>
        </section>
      </section>

      {/* <div className="PageContentWrapper">
        <div className="zakelijk-tile">
          <div className="zakelijk-tile-text left">
            <h2>
              {language === "NL"
                ? ZakelijkText[0].titel
                : ZakelijkText[0].titelEN}
            </h2>
            <p>
              {language === "NL"
                ? ZakelijkText[0].text
                : ZakelijkText[0].textEN}
            </p>
          </div>
          <img
            src={kantoorPlaatje}
            id="kantoorplaatje"
            alt="Escape room in jouw eigen kantoor"
          />
        </div>
        <div className="zakelijk-tile">
          <img src={klassement} id="klassement" alt="Escape room klassement" />
          <div className="zakelijk-tile-text right">
            <h2>
              {language === "NL"
                ? ZakelijkText[1].titel
                : ZakelijkText[1].titelEN}
            </h2>
            <p>
              {language === "NL"
                ? ZakelijkText[1].text
                : ZakelijkText[1].textEN}
            </p>
            <p>
              {language === "NL"
                ? "Bekijk "
                : "Find an example of a private ranking "}
              <a className="styled-a" href="./klassementen/demo">
                {language === "NL" ? "hier" : "here"}
              </a>{" "}
              {language === "NL"
                ? "een voorbeeld van een privé klassement."
                : "."}
            </p>
          </div>
        </div>
      </div> */}
    </div>
  );
}

export default Zakelijk;
