import React, { useState, useContext } from "react"
import "./PayPages.css"

import { faShoppingCart, faSpinner } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import IconBanner from "../shared/elements/IconBanner"

import { AppContext } from "../shared/context/AppContext"

function PayPageTemplate({ ERid }) {
  const { language } = useContext(AppContext)

  const [email1, setEmail1] = useState("")
  const [email2, setEmail2] = useState("")
  const [naam, setNaam] = useState("")
  const [discountCode, setDiscountCode] = useState("")
  const [discountError, setDiscountError] = useState("")
  const [EMAlert, setEMAlert] = useState(false)
  const [algVW, setAlgVW] = useState(false)
  const [error, setError] = useState("")
  const [isLoading, setIsLoading] = useState(false)
  const [discount, setDiscount] = useState(0)
  const price = 80

  const onChangeEmail1 = (e) => {
    setEmail1(e.target.value)
  }

  const onChangeEmail2 = (e) => {
    setEmail2(e.target.value)
  }

  const onChangeNaam = (e) => {
    setNaam(e.target.value)
  }
  const onChangeDiscount = (e) => {
    setDiscountCode(e.target.value)
  }

  const onChangeEMAlert = (e) => {
    setEMAlert(e.target.checked)
  }

  const onChangealgVW = (e) => {
    setAlgVW(e.target.checked)
  }

  const validateEmail = (email) => {
    var re = /\S+@\S+\.\S+/
    return re.test(email)
  }

  const handleCheckDiscount = (e) => {
    e.preventDefault()

    // Get payment URL from backend and create new order
    const checkCode = async () => {
      setIsLoading(true)
      try {
        const response = await fetch(
          process.env.REACT_APP_BACKENDURL + "/payments/discount/checkdiscount",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              code: discountCode,
            }),
          }
        )
        const responseData = await response.json()
        if (!response.ok) {
          language === "NL"
            ? console.log(
                "Er is iets fout gegaan op onze servers. Probeer het nog eens."
              )
            : console.log(
                "Something went wrong on our servers. Please try again."
              )
        }
        if (responseData.success) {
          setDiscount(10)
          setDiscountError("")
        } else {
          setDiscount(0)
          setDiscountCode("")

          language === "NL"
            ? setDiscountError("Kortingscode niet geldig.")
            : setDiscountError("Not a valid discount code.")
        }
        setIsLoading(false)
      } catch (err) {
        setIsLoading(false)
        language === "NL"
          ? setDiscountError(
              "Er is iets fout gegaan op onze servers. Probeer het nog eens."
            )
          : setDiscountError(
              "Something went wrong on our servers. Please try again."
            )
      }
    }
    checkCode()
  }

  const handleClickAfrekenen = (e) => {
    e.preventDefault()
    setError("")
    const emailsEqual = email1 === email2
    const email1Valid = validateEmail(email1)

    // Check if data is valid
    if (email1.length === 0) {
      language === "NL"
        ? setError("Voer een e-mailadres in")
        : setError("Enter an e-mail address")
      return
    } else if (!emailsEqual) {
      language === "NL"
        ? setError("E-mailadressen zijn niet gelijk")
        : setError("E-mail addresses are not equal")
      return
    } else if (!email1Valid) {
      language === "NL"
        ? setError("Geen bestaand e-mailadres")
        : setError("Not a valid e-mail address")
      return
    } else if (!algVW) {
      language === "NL"
        ? setError("Je moet akkoord gaan met de algemene voorwaarden")
        : setError("You have to accept the terms and conditions")
      return
    }

    // Get payment URL from backend and create new order
    const getData = async () => {
      setIsLoading(true)
      try {
        const response = await fetch(
          process.env.REACT_APP_BACKENDURL + "/payments/orders/createorderOEC",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              email: email1,
              escaperoom: "OEC",
              naam: naam,
              EMAlert: EMAlert,
              code: discountCode,
            }),
          }
        )
        const responseData = await response.json()
        if (!response.ok) {
          language === "NL"
            ? setError(
                "Er is iets fout gegaan op onze servers. Probeer het nog eens."
              )
            : setError("Something went wrong on our servers. Please try again.")
        }
        if (responseData.success) {
          // Redirect to payment URL
          setIsLoading(false)
          window.location.href = responseData.redirectUrl
        } else {
          language === "NL"
            ? setError(
                "Er is iets fout gegaan op onze servers. Probeer het nog eens."
              )
            : setError("Something went wrong on our servers. Please try again.")
        }
        setIsLoading(false)
      } catch (err) {
        setIsLoading(false)
        language === "NL"
          ? setError(
              "Er is iets fout gegaan op onze servers. Probeer het nog eens."
            )
          : setError("Something went wrong on our servers. Please try again.")
      }
    }
    getData()
  }

  return (
    <div className="PayPageWrapper" id="Competition-pay">
      <div className="PageContentWrapper">
        <section className="zakelijk-section cover-bg sand-bg">
          <section className="zakelijk-content">
            <h2 className="zakelijk-h2">
              {language === "NL"
                ? "Online Escape Competitie"
                : "Online Escape Competition"}
            </h2>
            <h3 className="subtitle-zakelijk">
              {language === "NL"
                ? "15 januari tot 25 februari 2023"
                : "January 15 to February 25, 2023"}
            </h3>
            <p>
              {language === "NL"
                ? "Vorm een team met 2-4 personen, 1 iemand hoeft zich maar in te schrijven per team. Je geeft je e-mailadres op en dat zullen wij gebruiken om het hele team op de hoogte te houden en om jullie t.z.t. de toegangscodes voor de 3 escape rondes te sturen."
                : "Form a team with 2-4 people, only 1 person needs to register per team. You provide your e-mail address and we will use it to keep the entire team informed and to send you the access codes for the 3 escape rounds."}
            </p>
          </section>
        </section>
        <div id="jouwBestelling" className="PayPageBlock">
          Inschrijven is helaas niet meer mogelijk.
        </div>
        {/* <div id="jouwBestelling" className="PayPageBlock">
          <h3 className="InfoPageTitle">
            {language === "NL"
              ? "Gegevens van jouw bestelling"
              : "Details of your order"}
          </h3>
          <br />

          <p>
            <div className="bestellingitem">
              <b>Product:</b>
            </div>{" "}
            {language === "NL"
              ? "Online Escape Competitie (1 team)"
              : "Online Escape Competitie (1 team)"}
          </p>
          <p>
            <span className="bestellingitem">
              <b>{language === "NL" ? "Prijs" : "Price"}:</b>
            </span>{" "}
            € {price},00
          </p>
        </div> */}
        {/* <div id="jouwKorting" className="PayPageBlock">
          <h3 className="InfoPageTitle">
            {language === "NL" ? "Kortingscode" : "Discount code"}
          </h3>

          <p>
            <input
              type="text"
              id="korting"
              className="korting_input"
              value={discountCode}
              onChange={onChangeDiscount}></input>
            <button id="submit-discount" onClick={handleCheckDiscount}>
              {language === "NL" ? "Toevoegen" : "Add"}
            </button>
          </p>
          <div className="errorWrapper">{discountError}</div>

          <p>
            <span className="bestellingitem">
              <b>{language === "NL" ? "Korting" : "Discount"}:</b>
            </span>{" "}
            € {discount},00
          </p>
          <p>
            <span className="bestellingitem">
              <b>{language === "NL" ? "Prijs na korting" : "New price"}:</b>
            </span>{" "}
            € {price - discount},00
          </p>
        </div> */}
        {/* <div id="jouwGegevens" className="PayPageBlock">
          <h3 className="InfoPageTitle">
            {language === "NL" ? "Jouw gegevens" : "Your credentials"}
          </h3>
          <br />
          <p>
            <div className="gegevensitem">
              <b>{language === "NL" ? "E-mailadres*" : "E-mail address*"}</b>
            </div>
            <input
              type="email"
              id="email1"
              className="email_input"
              value={email1}
              onChange={onChangeEmail1}></input>
          </p>
          <p>
            <div className="gegevensitem">
              <b>{language === "NL" ? "E-mail nogmaals" : "E-mail again"}</b>
            </div>{" "}
            <input
              type="email"
              id="email2"
              className="email_input"
              value={email2}
              onChange={onChangeEmail2}></input>
          </p>

          <p>
            <label htmlFor="EMalert" className="container">
              <div className="gegevensitem_long">
                <b>
                  {language === "NL"
                    ? "Opgeven voor Escape Mystery Alert**"
                    : "Sign up for Escape Mystery Alert**"}
                </b>
              </div>{" "}
              <input
                type="checkbox"
                id="EMalert"
                className="checkbox_input"
                value={EMAlert}
                onChange={onChangeEMAlert}></input>
              <span className="checkmark"></span>
            </label>
          </p>
          <p>
            <label htmlFor="algvw" className="container">
              <div className="gegevensitem_long">
                <b>
                  {language === "NL" ? "Akkoord met de " : "Agree to "}
                  <a href="/voorwaarden" target="_blank" rel="noreferrer">
                    {language === "NL"
                      ? "algemene voorwaarden"
                      : "terms and conditions"}
                  </a>
                </b>
              </div>{" "}
              <input
                type="checkbox"
                id="algvw"
                className="checkbox_input"
                value={algVW}
                onChange={onChangealgVW}
              />
              <span className="checkmark"></span>
            </label>
          </p>
          <p className="small">
            <br />
            {language === "NL"
              ? "* Naar dit e-mailadres worden de toegangscodes voor de escape rooms gestuurd. Zorg dus dat deze correct is."
              : "* The access code for the escape rooms will be sent to this e-mail address. So make sure it is correct."}
          </p>
          <p className="small">
            {language === "NL"
              ? "** Als je je aanmeldt voor de Escape Mystery Alert ontvang je een e-mail als er een nieuwe escape room uitkomt."
              : "** If you sign up for the Escape Mystery Alert you will receive an e-mail when a new escape eroom is released."}
          </p>
          <div className="errorWrapper">{error}</div>
        </div>{" "}
        <button
          className="new-info-button"
          onClick={handleClickAfrekenen}
          disabled={isLoading}>
          <div className="buttonIcon">
            {isLoading ? (
              <FontAwesomeIcon className="fa-spin" icon={faSpinner} />
            ) : (
              <FontAwesomeIcon icon={faShoppingCart} />
            )}
          </div>
          {language === "NL" ? "Afrekenen" : "Pay"}
        </button>
        <br />
        <br />
        <br /> */}
        <br />
      </div>
    </div>
  )
}

export default PayPageTemplate
