import React, { Fragment } from "react"
import "./PayPages.css"
import PayPageTemplate from "./PayPageTemplateOEC"
import { Helmet } from "react-helmet"

function PayDelftER() {
  return (
    <Fragment>
      <Helmet>
        <title>Inschrijven Online Escape Competitie | Escape Mystery</title>
      </Helmet>
      <PayPageTemplate />
    </Fragment>
  )
}

export default PayDelftER
