import React, { useContext, useEffect, useState } from "react"
import "./OECRanking.css"
import { Helmet } from "react-helmet"
import RoundRanking from "./rankings/roudRanking"
import AlgemeenRanking from "./rankings/AlgemeenRanking"
import SprintRanking from "./rankings/SprintRanking"

import { AppContext } from "../shared/context/AppContext"

function Competition() {
  const [rankingselect, setRankingselect] = useState("algemeen")
  const [currentRankingDiv, setCurrentRankingDiv] = useState()
  const { language, setActiveHeader } = useContext(AppContext)

  useEffect(() => {
    setActiveHeader("online_escape_competition")
    setCurrentRankingDiv(<AlgemeenRanking />)
  }, [])

  const handleChange = (e) => {
    setRankingselect(e.target.value)
    ChangeRanking(e.target.value)
  }

  const ChangeRanking = (selection) => {
    switch (selection) {
      case "algemeen":
        setCurrentRankingDiv(<AlgemeenRanking />)
        break
      case "sprint":
        setCurrentRankingDiv(<SprintRanking />)
        break
      case "round-1":
        setCurrentRankingDiv(<RoundRanking roundNumber={1} />)
        break
      case "round-2":
        setCurrentRankingDiv(<RoundRanking roundNumber={2} />)
        break
      case "round-3":
        setCurrentRankingDiv(<RoundRanking roundNumber={3} />)
        break
      default:
        setCurrentRankingDiv(<AlgemeenRanking />)
    }
  }

  return (
    <div className="Competition">
      <Helmet>
        <title>
          {language === "NL"
            ? "Online escape competitie | Escape Mystery"
            : "Online escape competition | Escape Mystery"}
        </title>
      </Helmet>
      <section className="zakelijk-section cover-bg sand-bg">
        <section className="zakelijk-content">
          <h2 className="zakelijk-h2">
            {language === "NL"
              ? "Klassementen Online Escape Competitie 2023"
              : "Rankings Online Escape Competition 2023"}
          </h2>
          <h3 className="subtitle-zakelijk">
            {language === "NL"
              ? "Bekijk hieronder de klassementen voor de Online Escape Competitie van 2023"
              : "Below you'll find the rankings for the Online Escape Competition"}
          </h3>
          <select
            name="rankings"
            id="ranking-dropdown"
            value={rankingselect}
            onChange={handleChange}>
            <option value="algemeen">
              {language === "NL" ? "Algemeen klassement" : "General standings"}
            </option>
            <option value="sprint">
              {language === "NL" ? "Sprint klassement" : "Sprint standings"}
            </option>
            <option value="round-1">
              {language === "NL" ? "Ronde 1" : "Round 1"}
            </option>
            <option value="round-2">
              {language === "NL" ? "Ronde 2" : "Round 2"}
            </option>
            <option value="round-3">
              {language === "NL" ? "Ronde 3" : "Round 3"}
            </option>
          </select>
        </section>
      </section>
      <section className="zakelijk-section cover-bg">
        {currentRankingDiv}
      </section>
    </div>
  )
}

export default Competition
