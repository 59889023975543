import React from "react"
import "./DiffIcon.css"
import { faLock } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

function DiffIcon({ diff, className }) {
  let diffIconDiv = []
  for (let i = 0; i < 5; i++) {
    diffIconDiv[i] =
      i < diff ? (
        <div className={`lockIcon ${className}`} key={i}>
          <FontAwesomeIcon icon={faLock} />{" "}
        </div>
      ) : (
        <div
          className="lockIcon"
          key={i}
          style={{ color: "rgb(255, 255, 255, 0.3)" }}>
          <FontAwesomeIcon icon={faLock} />{" "}
        </div>
      )
  }

  return <div className="DiffIcon">{diffIconDiv}</div>
}

export default DiffIcon
