import React, { useContext } from "react"
import "./IconBanner2.css"
import DiffIcon from "../../InfoPages/components/DiffIcon"
import { faEuroSign, faClock, faUsers } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import { AppContext } from "../context/AppContext"

function IconBanner({ difficulty, price, estTime, players, id }) {
  const { language } = useContext(AppContext)
  return (
    <div className="iconBanner2">
      <div className="iconsWrapper">
        <div className="iconInfoWrapper">
          <DiffIcon diff={difficulty} id={id} />
          {language === "NL" ? "Moeilijkheid" : "Difficulty"}
        </div>
        <div className="iconInfoWrapper">
          <div className={`red bigIcon ${id}-color`}>
            <FontAwesomeIcon icon={faClock} />
          </div>{" "}
          {estTime} {language === "NL" ? "minuten" : "minutes"}
        </div>
        <div className="iconInfoWrapper">
          <div className={`red bigIcon ${id}-color`}>
            <FontAwesomeIcon icon={faEuroSign}></FontAwesomeIcon>
          </div>{" "}
          {price === 0 ? (
            language === "NL" ? (
              "Gratis"
            ) : (
              "Free"
            )
          ) : (
            <div>
              {price}
              /team
            </div>
          )}
        </div>
        <div className="iconInfoWrapper">
          <div className={`red bigIcon ${id}-color`}>
            <FontAwesomeIcon icon={faUsers} />
          </div>{" "}
          {players}
        </div>
      </div>
    </div>
  )
}

export default IconBanner
