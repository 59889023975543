import React, { useState, useContext, useEffect } from "react"
import "./Klassement.css"
import { AppContext } from "../../shared/context/AppContext"
import KlassementTable from "./KlassementTable"

function RoundRanking({ roundNumber }) {
  const { language } = useContext(AppContext)
  const [isLoading, setIsLoading] = useState(true)
  const [notFound, setNotFound] = useState(false)
  let naam = ""
  const [klassementData, setKlassementData] = useState()

  if (roundNumber === 1) {
    naam = "comp_ronde_1"
  } else if (roundNumber === 2) {
    naam = "comp_ronde_2"
  } else {
    naam = "comp_ronde_3"
  }

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true)
      try {
        const response = await fetch(
          process.env.REACT_APP_BACKENDURL + "/website/rankings/getranking",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              rankingId: naam,
            }),
          }
        )
        const data = await response.json()

        if (!response.ok) {
          throw new Error(data.errorMsg)
        }

        setKlassementData(data.rankingData)
        setIsLoading(false)
      } catch (err) {
        setIsLoading(false)
        console.log(err.message)
        setNotFound(true)
      }
    }
    fetchData()
  }, [naam])

  const introtext = (
    <p className="intro-ranking">
      {language === "NL"
        ? `In dit klassement vind je de tijden van alle teams voor ronde ${roundNumber}. Zodra een team de ronde heeft afgerond verschijnt de eindtijd van dit team in dit klassement. `
        : `In the general standings, the total times for all rounds are added together. So this is the total time for each round including penalty time for buying hints or entering wrong answers.`}
    </p>
  )

  if (isLoading) {
    return (
      <div className="Klassement">
        <div className="PageContentWrapper" id="klassement-wrapper">
          {introtext}
          <h2 id="loading">Loading...</h2>
        </div>
      </div>
    )
  } else if (notFound) {
    return <div></div>
  } else {
    return (
      <div className="Klassement">
        <div className="PageContentWrapper" id="klassement-wrapper">
          {introtext}
          {klassementData ? <KlassementTable data={klassementData} /> : ""}
        </div>
      </div>
    )
  }
}

export default RoundRanking
