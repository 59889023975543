import React, { useEffect, useContext } from "react"
import Quotesdata from "./Quotesdata"
import "./Carousel.css"
import { faQuoteRight, faQuoteLeft } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { AppContext } from "../../shared/context/AppContext"

let slideIndex = 0

function Carousel() {
  const { language } = useContext(AppContext)
  useEffect(() => {
    let timer1
    const carousel = () => {
      try {
        const x = document.getElementsByClassName("mySlides")
        for (let i = 0; i < x.length; i++) {
          x[i].style.display = "none"
        }
        slideIndex++
        if (slideIndex > x.length) {
          slideIndex = 1
        }
        x[slideIndex - 1].style.display = "block"
        timer1 = setTimeout(carousel, 8000)
      } catch {}
    }
    carousel()
    return () => {
      clearTimeout(timer1)
    }
  }, [])

  const quotesComponents = Quotesdata.map((quote) => {
    return (
      <div className="mySlides" key={quote.id}>
        <div className="quoteText">
          <div className="quoteIconWrapper">
            <FontAwesomeIcon icon={faQuoteLeft}> </FontAwesomeIcon>
          </div>
          <i>{language === "NL" ? quote.quote : quote.quoteEN}</i> -{" "}
          {quote.name}
          <div className="quoteIconWrapper">
            <FontAwesomeIcon icon={faQuoteRight}> </FontAwesomeIcon>
          </div>
        </div>
      </div>
    )
  })

  return <div className="carousel">{quotesComponents}</div>
}

export default Carousel
