import React, { useContext } from "react"
import "./PaymentComplete.css"
import EscapeRoomData from "../EscaperoomsPage/components/EscapeRoomData"
import { Helmet } from "react-helmet"

import { useParams } from "react-router"

import { AppContext } from "../shared/context/AppContext"

function PaymentComplete() {
  const { language } = useContext(AppContext)
  const { orderId, ERid } = useParams()
  const { title, titleEN, URL, themeColor, id } = EscapeRoomData.find(
    (x) => x.id === ERid
  )

  return (
    <div className="PaymentComplete matrix-bg">
      <style>
        {`.${id}-background-color {background-color: ${themeColor};} 
        .${id}-color {color: ${themeColor};}
        .${id}-border-color {border-color: ${themeColor};}
        `}
      </style>
      <Helmet>
        <title>Betaal bevestiging | Escape Mystery</title>
      </Helmet>
      <div className="PageContentWrapper">
        <br />
        <h2 className="zakelijk-h2">
          {language === "NL" ? "Betaal bevestiging" : "Payment Confirmation"}
        </h2>
        <div className="PageSubTitleText" id="EscapeRoomsSubTitle">
          {language === "NL"
            ? "Bedank voor jouw bestelling met ordernummer "
            : "Thank you for your order with reference "}
          {orderId}
        </div>
        <div id="completed" className={`PayPageBlock ${id}-border-color`}>
          <h3 className="info-page-title">
            {" "}
            {language === "NL"
              ? "E-mail met toegangscode"
              : "E-mail with access code"}
          </h3>
          <p className="simple-text">
            {language === "NL"
              ? `Je krijgt binnen 10 minuten een e-mail met daarin de toegangscode voor ${title}. Jullie kunnen dan direct inloggen met de toegangscode en jouw avontuur in onze escape room beginnen. Heel veel plezier!`
              : `You will receive an email within 10 minutes containing the access code for ${titleEN}. You can then log in directly with the access code and start your adventure in our escape room. Have fun!`}
          </p>
          <a href={URL} target="_blank" rel="noreferrer">
            <button
              id="playButton"
              className={`new-info-button ${id}-background-color`}>
              {language === "NL"
                ? `Inloggen bij ${title}`
                : `Login to ${title}`}
            </button>
          </a>
        </div>
        <div id="completed" className={`PayPageBlock ${id}-border-color`}>
          <h3 className="info-page-title">
            {language === "NL"
              ? "Geen e-mail ontvangen?"
              : "Didn't receive an email?"}
          </h3>
          <p className="simple-text">
            {language === "NL"
              ? `Heb je binnen 10 minuten geen e-mail ontvangen? Kijk eerst in de
            spam of ongewenste e-mail. Als je ook daar niks kan vinden, stuur
            dan een mailtje naar helpdesk@escapemystery.nl en noem daarin jouw
            ordernummer: ${orderId}`
              : `Didn't receive an email within 10 minutes? Check the spam or junk email first. If you can't find anything there either, send an email to helpdesk@escapemystery.nl and mention your reference: ${orderId}`}
          </p>
        </div>
      </div>
    </div>
  )
}

export default PaymentComplete
