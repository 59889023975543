import React, { useContext, useEffect } from "react"
import "./Competition.css"
import { Helmet } from "react-helmet"
import { Link } from "react-router-dom"
import {
  faCalendarAlt,
  faEuroSign,
  faUsers,
} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import { AppContext } from "../shared/context/AppContext"

import gifcsi from "../EscaperoomsPage/img/Gif_SterrenER.gif"
import gifruimte from "../EscaperoomsPage/img/Gif_MoordER.gif"

function Competition() {
  const { language, setActiveHeader } = useContext(AppContext)

  useEffect(() => {
    setActiveHeader("online_escape_competition")
  }, [])

  return (
    <div className="Competition">
      <Helmet>
        <title>
          {language === "NL"
            ? "Online escape competitie | Escape Mystery"
            : "Online escape competition | Escape Mystery"}
        </title>
      </Helmet>
      <section className="zakelijk-section cover-bg sand-bg">
        <section className="zakelijk-content">
          <h2 className="zakelijk-h2">
            {language === "NL"
              ? "Online Escape Competitie"
              : "Online Escape Competition"}
            {language !== "NL" ? <br /> : ""}
            {language !== "NL" ? "[Dutch only]" : ""}
          </h2>
          <h3 className="subtitle-zakelijk">
            {language === "NL"
              ? "Puzzel, versla je vrienden en win!"
              : "Puzzle, beat your friends and win!"}
          </h3>
          <h4>{language === "NL" ? "Hoe werkt het?" : "How does it"}</h4>
          <br /> <br />
          <img src={gifcsi} className="img-right no-show-mobile" alt="" />
          <p>
            {language === "NL"
              ? "Met een team van 2-4 spelers speel je drie keer een online escape room. Je neemt het op tegen alle andere teams die meespelen. Elke escape room zal zo’n 1 a 2 uur duren en je hebt 2 weken de tijd om hem te voltooien. Per teams kost het 80 euro. Hiervoor krijg je drie gloednieuwe virtuele escape rooms en een spannende competitie! Elke escape room is een virtuele 360° escape room zoals je van Escape Mystery gewend bent. Lukt het jullie een van de klassementen te winnen?!"
              : "With a team of 2-4 players you play three online escape rooms. You compete against all other teams. Each escape room will take about 1 to 2 hours and you have 2 weeks to complete it. It costs 80 euros per team. For this you get three brand new escape rooms and an exciting competition! Can you win one of the rankings?!"}
          </p>
          <section className="zakelijk-content">
            <Link to="/escaperooms/pay/online_escape_competitie">
              <div className="new-info-button">
                {language === "NL"
                  ? "Schrijf je team in"
                  : "Register your team"}
              </div>
            </Link>
          </section>
        </section>
      </section>
      <section className="zakelijk-section light-bg">
        <section
          className="zakelijk-content flex centeralign"
          id="summary-competition">
          <div>
            <FontAwesomeIcon icon={faCalendarAlt} className="summary-fa" />
            <p>
              <b>
                {language === "NL"
                  ? "15 januari tot 25 februari 2023"
                  : "January 16 to February 26, 2023"}
              </b>
              <br />
              <br />
              {language === "NL"
                ? "Ronde #1: 15 jan - 28 jan"
                : "Round #1: Jan 15 - Jan 28"}
              <br />
              {language === "NL"
                ? "Ronde #2: 29 jan - 11 feb"
                : "Round #2: Jan 29 - Feb 11"}
              <br />
              {language === "NL"
                ? "Ronde #3: 12 feb - 25 feb"
                : "Round #3: Feb 12 - Feb 25"}
            </p>
          </div>
          <div>
            <FontAwesomeIcon icon={faEuroSign} className="summary-fa" />
            <p>
              <b>€80 / team</b>
              <br />
              <br />
              <p>
                {language === "NL"
                  ? "Drie escape rooms in een spannende competitie. Speel met een team van 2-4 personen."
                  : "Three escape rooms in an exciting competition. Play with a team of 2-4 people."}
              </p>
            </p>
          </div>
          <div>
            <FontAwesomeIcon icon={faUsers} className="summary-fa" />
            <p>
              {language === "NL"
                ? "Iedereen kan mee doen! Je krijgt met je team voorafgaand aan elke ronde een toegangscode voor de escape room waarmee iedereen kan inloggen."
                : "Anyone can participate. You'll get a access code before each round. The whole team can log in with that access code."}
            </p>
          </div>
        </section>
      </section>
      <section className="zakelijk-section cover-bg lock-bg">
        <section className="zakelijk-content">
          <h2 className="zakelijk-h2">
            {language === "NL" ? "De competitie" : "The competition"}
          </h2>
          <p className="centeralign">
            {language === "NL"
              ? "Er zijn drie soorten klassementen waarin alle teams meestrijden. In elk klassement zijn mooie prijzen te winnen! Na elke ronde worden de klassementen opgemaakt en worden de rondewinnaars bekend gemaakt."
              : "There are three types of rankings in which all teams compete."}
          </p>
        </section>
        <section className="zakelijk-content flex">
          <div className="klassementen-div">
            <b>
              {language === "NL" ? "Algemeen klassement" : "General ranking"}
            </b>
            <br />
            <br />
            <p>
              {language === "NL"
                ? "In het algemeen klassement worden de totale tijden bijgehouden van alle deelnemers. In dit klassement zijn de mooiste prijzen te winnen!"
                : "In the general ranking, the total times of all participants are kept. In this ranking, the best prizes can be won!"}
            </p>
          </div>

          <div className="klassementen-div">
            <b>{language === "NL" ? "Sprint klassement" : "Sprint ranking"}</b>
            <br />
            <br />
            <p>
              {language === "NL"
                ? "Elke escape room heeft een sprintpuzzel. Dit is een puzzel die ongeveer 5-15 minuten zal duren. Het draait in dit klassement dus echt om snelheid!"
                : "Each escape room has a sprint puzzle. This is a puzzle that will take about 5-15 minutes. It's really all about speed in this ranking!"}
            </p>
          </div>
          <div className="klassementen-div">
            <b> {language === "NL" ? "Ronde klassement" : "Round ranking"}</b>
            <br />
            <br />
            <p>
              {language === "NL"
                ? "Elke ronde is er een apart klassement voor de escape room van die ronde. Los van hoe je in het algemeen klassement staat is er dus altijd iets te winnen!"
                : "Each round there is a separate leaderboard for the escape room of that round. Regardless of how you rank in the general ranking, there is always something to win!"}
            </p>
          </div>
        </section>
      </section>
      <section className="zakelijk-section light-bg">
        <section className="zakelijk-content">
          <h2 className="zakelijk-h2">
            {language === "NL" ? "De escape rooms" : "The escape rooms"}
          </h2>
          <div>
            <img src={gifruimte} alt="" id="escaperooms-gif" />
            <p id="competition-escape-room-text">
              {language === "NL"
                ? "In de Online Escape Competitie speel je drie nieuwe escape rooms van Escape Mystery. Elke ronde is een online escape room in 360 graden zoals je van ons gewend bent. Voorafgaan aan elke ronde ontvang je een toegangscode waarmee het hele team kan inloggen zodat je gezellig samen, ieder op zijn eigen laptop, kan puzzelen. In elke virtuele escape room zijn aanwijzingen en puzzels te vinden waarmee je stap voor stap dichter bij het eindantwoord komt. Je kan gebruik maken van alle middelen die je kan vinden. Soms heb je het internet nodig, soms moet je samenwerken en soms moet je goed zoeken in de digitale ruimte. Je komt nooit vast te zitten in de escape room, want na bepaalde tijd krijg je hints waarmee je verder kan puzzelen. Speel ‘Gevaar uit de Ruimte’ om een goed idee te krijgen van hoe een online escape room van Escape Mystery er uit ziet."
                : "In the Online Escape Competition you will play three new escape rooms from Escape Mystery. Each round is an online escape room in 360 degrees. Prior to each round you will receive an access code which the whole team can use to log in so you can puzzle together, each on their own laptop. In each virtual escape room you will find clues and puzzles that will bring you step by step closer to the final answer. You can use whatever resources you can find. Sometimes you need the Internet, sometimes you have to work together and sometimes you have to search the digital rooms. You will not get stuck in the escape room, because after certain time you'll get hints. Play 'Danger from Space' to get a good idea of what an online escape room from Escape Mystery looks like."}
            </p>
          </div>
          <div className="button-wrapper-zakelijk">
            <Link
              to="/escaperooms/info/gevaar_uit_de_ruimte/"
              target="_blank"
              rel="noopener noreferrer">
              <div className="new-info-button">
                {language === "NL"
                  ? "Speel 'Gevaar uit de ruimte!''"
                  : "Play 'Danger from space!'"}
              </div>
            </Link>

            <Link to="/escaperooms/" target="_blank" rel="noopener noreferrer">
              <div className="new-info-button">
                {language === "NL"
                  ? "Bekijk alle escape rooms"
                  : "Check all the escape rooms"}
              </div>
            </Link>
          </div>
        </section>
      </section>

      <section className="zakelijk-section matrix-bg">
        <section className="zakelijk-content">
          <h2 className="zakelijk-h2">
            {language === "NL" ? "Inschrijven" : "Registration"}
          </h2>
          <p className="centeralign">
            {language === "NL"
              ? "Vorm een team met 2-4 personen waarvan minstens één iemand Nederlands spreekt. Eén iemand hoeft zich maar in te schrijven per team. Je geeft je e-mailadres op en dat zullen wij gebruiken om het hele team op de hoogte te houden en om jullie t.z.t. de toegangscodes voor de 3 escape rondes te sturen."
              : "Form a team with 2-4 people where at least one person speaks Dutch. Only one person needs to register per team. You provide your e-mail address and we will use it to keep the entire team informed and to send you the access codes for the 3 escape rounds."}
          </p>
          <br />
          <Link to="/escaperooms/pay/online_escape_competitie">
            <div className="new-info-button">
              {language === "NL" ? "Schrijf je team in" : "Register your team"}
            </div>
          </Link>
        </section>
      </section>
      <section className="zakelijk-section light-bg">
        <section className="zakelijk-content">
          <h2 className="zakelijk-h2">
            {language === "NL" ? "Vragen?" : "Question?"}
          </h2>
          <p className="centeralign">
            {language === "NL" ? "Bekijk onze " : "Check our "}
            <Link to="/faq" className="styled-a">
              FAQ
            </Link>
            {language === "NL"
              ? " voor veelgestelde vragen of stuur gerust een mail naar "
              : " for frequently asked questions or send an email to "}
            <a href="mailto:competition@escapemystery.nl" className="styled-a">
              competitie@escapemystery.nl
            </a>
            {language === "NL"
              ? ", dan beantwoorden we je vraag zo snel mogelijk!"
              : ", and we will answer your question as soon as possible!"}
          </p>
          <br />
        </section>
      </section>
    </div>
  )
}

export default Competition
